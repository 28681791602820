<template>
  <v-container fluid>
    <v-card class="elevation-11">
      <v-card-title class="accent">
        <h3>eTickets</h3>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatStartDate"
                    label="Start Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  :min="this.calcMinDate()"
                  :max="this.calcMaxDate()"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatEndDate"
                    label="End Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  :min="this.calcMinDate()"
                  :max="this.calcMaxDate()"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn
                color="btn-large btn-block primary white--text"
                class="mr-2"
                @click="getTickets"
                :disabled="!valid"
                >Get Tickets</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" elevation-11>
      <v-card-title>
        <v-col cols="12" md="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :search="search"
        :headers="headers"
        :items="tickets"
        item-key="invkey"
        class="elevation-1"
        loading-text="Retrieving data... please wait"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium @click="downloadTicket(item)">mdi-download</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import api from "../../services/axiosconfig";
import moment from "moment";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { aejImgData, iataImgData } from "../../services/logos";

export default {
  data() {
    return {
      valid: true,
      search: "",
      loading: false,
      headers: [
        { text: "Type", align: "start", value: "type" },
        { text: "Ticket No.", align: "start", value: "ticketno" },
        { text: "Issue Date", align: "start", value: "issuedate" },
        { text: "Passenger", align: "start", value: "passenger" },
        { text: "Destination", align: "start", value: "finaldest" },
        { text: "Departure", align: "start", value: "depdate" },
        { text: "Locator", align: "start", value: "locator" },
        { text: "Inv No.", align: "start", value: "invoicenum" },
        { text: "Inv Date", align: "start", value: "invoicedate" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      tickets: [],
      pdfdata: [],
      selectedItem: {},
      selectedIndex: -1,
      ledgerkey: "",

      startDateMenu: false,
      endDateMenu: false,

      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),

      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),

      caption: "Business & Leisure Travel Management",
      address: "No.35 St.John's Road, Isleworth, Middlesex TW7 6NY",
      telno: "Tel No. 020 8560 2458",
      email: "Email: accounts@aejtravels.co.uk",
      notes: [
        "IMPORTANT NOTICE : This is a special fare ticket and may be non refundable. Please check all conditions.Cancellations and changes may be restricted so check if in doubt. Please ensure that you have valid travel documents & visas for your journey. Please reconfirm all flights at least 72 hours before departure, failure to do so may result in cancellation of your booking. Thank you.",
      ],
    };
  },
  mounted() {
    this.getCurrentLedgerKey();
  },

  created() {
    this.$emit(`update:layout`, AdminLayout);
  },

  computed: {
    formatStartDate() {
      return `${moment(this.startDate).format("DD-MM-YYYY")}`;
    },
    formatEndDate() {
      return `${moment(this.endDate).format("DD-MM-YYYY")}`;
    },
  },

  methods: {
    calcMinDate() {
      return moment()
        .subtract(localStorage.getItem("PERMITTED"), "months")
        .startOf("month")
        .format("YYYY-MM-DD");
    },

    calcMaxDate() {
      return moment().endOf("month").format("YYYY-MM-DD");
    },

    validateData() {
      if (moment(this.startDate).isAfter(this.endDate)) {
        this.$toasted.global.optimus_error({
          message: "Start date must be on or before the end date.",
        });
        return false;
      } else {
        return true;
      }
    },

    dateFormat(dateString) {
      return moment(new Date(dateString)).format("DD-MM-YYYY");
    },

    getCurrentLedgerKey() {
      this.ledgerKey = localStorage.getItem("LEDG_KEY");
    },

    async getTickets() {
      if (this.validateData()) {
        this.tickets = [];
        this.pdfdata = [];
        try {
          this.loading = true;
          const response = await api.post("/aejmis/api/tickets", {
            startdate: this.startDate,
            enddate: this.endDate,
            ledgerkey: this.ledgerKey,
          });
          this.pdfdata = response.data;
          this.pdfdata.map(
            ({
              invkey,
              invoicenum,
              invoicedate,
              finaldest,
              depdate,
              locator,
              firstname,
              lastname,
              costing,
            }) => {
              this.tickets.push({
                invkey: invkey,
                invoicenum: invoicenum,
                invoicedate: this.dateFormat(invoicedate),
                passenger: `${firstname.trim()} ${lastname.trim()}`,
                finaldest: finaldest,
                depdate: this.dateFormat(depdate),
                locator: locator,
                type: this.getTicketType(costing),
                ticketno: this.getTicketNum(costing),
                issuedate: this.getTicketDate(costing),
              });
            }
          );
          this.loading = false;
        } catch (error) {
          console.log(error);
          this.loading = false;
          this.$toasted.global.optimus_error({
            message: "Could not retrieve information. Try after sometime.",
          });
        }
      }
    },
    getTicketType(costing) {
      let tickettype = "";
      costing.forEach((costItem) => {
        costItem.ticket.forEach((ticketItem) => {
          tickettype = ticketItem.adchinfcon;
        });
      });
      return tickettype;
    },

    getTicketNum(costing) {
      let ticketno = "";
      costing.forEach((costItem) => {
        costItem.ticket.forEach((ticketItem) => {
          ticketno = ticketItem.ticketnum;
        });
      });
      return ticketno;
    },
    getTicketDate(costing) {
      let ticketdt = "";
      costing.forEach((costItem) => {
        costItem.ticket.forEach((ticketItem) => {
          ticketdt = ticketItem.issuedate;
        });
      });
      return this.dateFormat(ticketdt);
    },
    downloadTicket(item) {
      const selectedIndex = this.tickets.indexOf(item);
      // let tktnum = this.tickets[selectedIndex].ticketno;
      const selectedItem = this.pdfdata[selectedIndex];

      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      var pageWidth = doc.internal.pageSize.getWidth();
      var pageHeight = doc.internal.pageSize.getHeight();

      doc.addImage(aejImgData, "png", 10, 10);
      doc.addImage(iataImgData, "png", pageWidth - 47, 7);

      doc.setFontSize(12).text(this.caption, 10, 42);

      let addressX = pageWidth - (doc.getTextWidth(this.address) - 6);
      doc.setFontSize(10).text(this.address, addressX, 32);

      let telnoX = pageWidth - (doc.getTextWidth(this.telno) + 11);
      doc.setFontSize(10).text(this.telno, telnoX, 37);

      let emailX = pageWidth - (doc.getTextWidth(this.email) + 11);
      doc.setFontSize(10).text(this.email, emailX, 42);

      doc.setLineWidth(0.01).line(10, 46, 200, 46);

      const header = "ELECTRONIC TICKET";
      let headerX = (pageWidth - doc.getTextWidth(header)) / 2 - 10;
      doc.setFont("helvetica").setFontSize(16).text(header, headerX, 53);

      doc.autoTable({
        body: [
          [
            {
              content: "Reservation No.",
              styles: {
                halign: "left",
                fontSize: 16,
              },
            },

            {
              content: selectedItem.locator,
              styles: {
                halign: "right",
                fontSize: 16,
              },
            },
          ],
        ],
        margin: { left: 10, top: 55 },
        theme: "plain",
      });

      // Ticket Details
      let tickets = [];
      selectedItem.costing.forEach((costItem) => {
        costItem.ticket.forEach((ticketItem) => {
          tickets.push(
            new Array(
              ticketItem.adchinfcon,
              ticketItem.passengername,
              ticketItem.specifier,
              ticketItem.ticketnum,
              this.dateFormat(ticketItem.issuedate)
            )
          );
        });
      });

      doc.autoTable({
        head: [
          ["Type", "Passenger Name", "Airline", "Ticket No.", "Issue Date"],
        ],
        body: tickets,
        theme: "plain",
        tableLineWidth: 0.1,
        tableLineColor: [0, 0, 0],
      });

      // Routing Details
      let routes = [];
      selectedItem.routing.forEach((item) => {
        let printDate = true;
        const depDateYear = new Date(item.departuredate).getFullYear();
        if (depDateYear === 1900) {
          printDate = false;
        } else {
          printDate = true;
        }
        routes.push(
          new Array(
            item.from,
            item.destination,
            item.airline.trim() + item.flight.trim(),
            printDate ? item.class : "",
            printDate ? this.dateFormat(item.departuredate) : "",
            item.departuretime,
            item.arrivaltime,
            item.status
          )
        );
      });

      doc.autoTable({
        head: [
          [
            "From",
            "Destination",
            "Flight",
            "Class",
            "Date",
            "Departure",
            "Arrival",
            "Status",
          ],
        ],
        body: routes,
        theme: "plain",
        tableLineWidth: 0.1,
        tableLineColor: [0, 0, 0],
      });

      doc.setLineWidth(0.01).line(10, pageHeight - 30, 200, pageHeight - 30);

      doc
        .setFont("helvetica")
        .setFontSize(8)
        .text(this.notes, 10, pageHeight - 25, {
          align: "justify",
          maxWidth: "190",
        });

      doc.setLineWidth(0.01).line(10, pageHeight - 15, 200, pageHeight - 15);

      // Page Numbers and Total Pages
      const pageCount = doc.internal.getNumberOfPages();

      for (var i = 1; i <= pageCount; i++) {
        const pageNum = `Page ${String(i)} of ${String(pageCount)}`;
        let pageX = pageWidth - (doc.getTextWidth(pageNum) + 12);
        doc.setPage(i);
        doc
          .setFont("helvetica")
          .setFontSize(9)
          .text(pageNum, pageX, pageHeight - 10);
      }

      // Footer Content
      const footer = "VAT No. 859 0048 13";
      doc
        .setFont("helvetica")
        .setFontSize(10)
        .text(footer, 10, pageHeight - 10);

      // doc.save(`AEJ-TICKET-${tktnum}.pdf`);
      window.open(doc.output("bloburl"), "_blank");
    },
  },
};
</script>
